const DownloadAppButtonsStyle: any = (theme: any) => ({
  root: {},
  title: {
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#003032",
    marginBottom: "16px",
  },
  link: {
    textDecoration: "none",
  },
  button: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    marginRight: "6px",
  },
  image: {
    width: "176px",
    height: "auto",
    [theme.breakpoints.down(400)]: {
      width: "130px",
    },
  },
});

export default DownloadAppButtonsStyle;
