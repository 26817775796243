import React, { ReactElement, useState } from "react";
import { makeStyles } from "@mui/styles";
import DownloadAppStyles from "./DownloadAppButtons.styles";
import { imageParser } from "../../../shared/utils/image.utils";
import * as ga from "../../common/UtilsGA";
import { useViewport } from "../../../shared/hooks";

const useStyles = makeStyles(DownloadAppStyles);

export const DownloadAppButtons = (
  props: DownloadAppButtonsProps
): ReactElement => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const below600 = useViewport(600).below;

  return (
    <div className={classes.root}>
      <a href={props.playStoreLink} className={classes.link}>
        <button
          style={{ padding: "0" }}
          className={classes.button}
          aria-label="Download Kenko App from Playstore"
        >
          <img
            style={{
              height: below600 ? "26px" : "",
              width: below600 ? "91px" : "",
            }}
            src={
              loaded
                ? imageParser("download_buttons/google_store.png")
                : imageParser("download_buttons/google_store.png")
            }
            alt="Get kenko app on google play store"
            onLoad={() => setLoaded(true)}
            onClick={() => {
              ga.event({
                action: "web_play_store_app_link_clicked",
                params: {
                  Page_Url: window.location.href,
                  BannerName: props.Banner,
                },
              });
              window?.Moengage?.track_event("web_play_store_app_link_clicked", {
                Page_Url: window.location.href,
                BannerName: props.Banner,
              });
            }}
            // style={{ transform: matchesSM?"scale(0.8)":'unset' }}
          />
        </button>
      </a>
      <a href={props.appStoreLink} className={classes.link}>
        <button
          className={classes.button}
          aria-label="Download Kenko App from App Store"
        >
          <img
            style={{
              height: below600 ? "26px" : "",
              width: below600 ? "91px" : "",
            }}
            src={
              loaded
                ? imageParser("download_buttons/app_store.png")
                : imageParser("download_buttons/app_store.png")
            }
            alt="Get kenko app on apple store"
            onLoad={() => setLoaded(true)}
            onClick={() => {
              ga.event({
                action: "web_app_store_app_link_clicked",
                params: {
                  Page_Url: window.location.href,
                },
              });
              window?.Moengage?.track_event("web_app_store_app_link_clicked", {
                Page_Url: window.location.href,
              });
            }}
            // style={{ transform: matchesSM?"scale(0.8)":'unset' }}
          />
        </button>
      </a>
    </div>
  );
};

type DownloadAppButtonsProps = {
  playStoreLink?: string;
  appStoreLink?: string;
  Banner?: string;
} & typeof defaultProps;

const defaultProps = {
  playStoreLink:
    "https://play.google.com/store/apps/details?id=com.redkenko.health",
  appStoreLink:
    "https://apps.apple.com/in/app/kenko-all-in-one-health-plans/id1616791973",
};

DownloadAppButtons.defaultProps = defaultProps;
