import { imageParser } from "../shared/utils/image.utils";

const appShowcaseContent = {
  MOBILE_WRAPPER_IMAGE: "kenko_steps_section/phone_frame.webp",
  CONTENT_DOWNLOAD_APP_SECTION: [
    {
      id: 1,
      HERO_IMAGE: "download_app_section/hero_download_app_3.webp",
      HERO_IMAGE_ALT: "Kenko Healthcare App - Your Personal Health Companion",
      TITLE: "<p class='title'>Our app is where the magic is!</p>",
      SUB_TITLE:
        "<p class='subTitle'>Order medicines, book lab tests and get all your benefits</p>",
      TITLE_M: "<p class='titleM'>Our app is where <br/> the magic is!</p>",
      SUB_TITLE_M:
        "<p class='subTitleM'>Order medicines, book lab tests and get all your benefits</p>",
    },

    {
      id: 2,
      HERO_IMAGE: "download_app_section/hero_download_app_1.webp",
      HERO_IMAGE_ALT:
        "Explore categories with the Kenko Healthcare App- Shop today",
      TITLE: `
        <img 
          src="${imageParser("download_app_section/shoho_store_logo.svg")}" 
          alt="Shoho - Your Trusted Partner for Online Healthcare" 
          style="margin-left: -3vw"
        />
      `,
      SUB_TITLE:
        "<p class='subTitle'>Order daily healthcare items on discounts and get instant home delivery</p>",
      TITLE_M: `
        <img 
          src="${imageParser("download_app_section/shoho_store_logo.svg")}" 
          alt="Shoho - Your Trusted Partner for Online Healthcare" 
          class="shoho-store-logo" 
        />`,
      SUB_TITLE_M:
        "<p class='subTitleM'>Order daily healthcare items on discounts and get instant home delivery</p>",
    },

    {
      id: 3,
      HERO_IMAGE: "download_app_section/hero_download_app_2.webp",
      HERO_IMAGE_ALT:
        "Explore your profile with kenko healthcare and know your score",
      TITLE: "<p class='title'>Manage your health profile</p>",
      SUB_TITLE:
        "<p class='subTitle'>Track your health status and align your healthcare services</p>",
      // To do: Remove Hotfix
      TITLE_M: "<p class='titleM'>Manage your <br/>  health profile</p>",
      SUB_TITLE_M:
        "<p class='subTitleM'>Track your health status and align your healthcare services</p>",
    },
  ],
};

export default appShowcaseContent;
